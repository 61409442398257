<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-button type="primary" @click="loginKf(record, undefined)">
        <a-icon type="plus1" />{{$t('通用.按钮.平台客服')}}
      </a-button>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {ptkf} from '@/api/shop/shop'
export default {
  name: 'Shop',
  components: {

  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {
    /** 编辑商品信息 **/
    loginKf(row) {
      ptkf().then(response => {
        // window.location.href = response.data
        window.open(response.data, 'kfWidow',"666");
        /*let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
        window.open(openUrl, 'kfWidow',"444");*/
      })
    },
  }
}
</script>
